// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/logo.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".ggcontainer{background:#fff;height:100%;width:100%}.ggcontainer .logo{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:contain;background-repeat:no-repeat;width:20%;height:40px;margin:20px auto 0 auto}.ggcontainer .title{text-transform:uppercase;color:#4f5c68;font-size:1.8em;font-weight:800;line-height:1em;text-align:center;width:100%;display:inline-block;margin:10px auto 10px auto}@media screen and (min-width:768px){.ggcontainer .title{font-size:3.2em}}@media screen and (min-width:1024px){.ggcontainer .title{font-size:5em}}.ggcontainer .span{display:block;width:auto;padding:10px;margin:0 auto;color:#688194;text-align:center;text-decoration:underline}.ggcontainer .gactions{display:flex;align-items:center;margin:40vh auto 0 auto}.landscape .ggcontainer .span{position:fixed;width:100%;left:0;bottom:0}", ""]);
// Exports
module.exports = exports;
