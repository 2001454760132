<template>
  <div
    ref="btn"
    :class="['btn_gender', gender, animated ? 'btn_effect' : '']"
    @touchstart="animate($event)"
    @touchmove="determineTouchzone($event)"
    @touchend="action($event, genderSignal)"
    @click="action($event, genderSignal)"
  >
    <img
      class="gendericon"
      :src="gendericon"
    >
  </div>
</template>

<script>
import * as Sentry from '@sentry/vue'

export default {
  name: 'GenderButton',
  props: {
    gender: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      animated: false,
      inTouchZone: false,
    }
  },
  computed: {
    genderSignal() {
      if (this.gender === 'male') {
        return 'm'
      } else if (this.gender === 'female') {
        return 'f'
      } else {
        return 'x'
      }
    },
    gendericon() {
      if (this.gender === 'male') {
        return require('../assets/images/gender-man.png')
      } else if (this.gender === 'female') {
        return require('../assets/images/gender-woman.png')
      } else {
        return require('../assets/images/gender-x.png')
      }
    },
  },
  methods: {
    animate(event) {
      if (event.handled === false) return
      event.stopPropagation()
      event.preventDefault()
      event.handled = true

      this.animated = true
    },
    determineTouchzone(event) {
      // we cannot measure touch zone on click events, return true
      if (event.type === "click") {this.inTouchZone = true; return true}
      // handle touch zone bounding box
      // this is important so we can properly distinguish between "hovering" over button and clicks
      var touch = event.touches[0] || event.changedTouches[0]

      const btn = this.$refs.btn
      const rect = btn.getBoundingClientRect()
      const x = touch.clientX
      const y = touch.clientY

      if (
        rect.top + rect.height > y &&
        rect.top < y &&
        rect.left < x &&
        rect.left + rect.width > x
      )
        this.inTouchZone = true
      else this.inTouchZone = false
    },
    action(event, a) {
      if (event.handled === false) return
      event.stopPropagation()
      event.preventDefault()
      event.handled = true

      this.determineTouchzone(event)
      this.animated = false
      if (this.inTouchZone) {
        this.$emit('clicked', a)
        Sentry.addBreadcrumb({
          type: 'user',
          category: 'ui.click',
          message: '[GenderGate] selected gender',
          timestamp: new Date(),
        })
      }
    },
  },
}
</script>

<style lang="scss">
.btn_effect {
  transform: scale(0.9);
}

.btn_gender {
  position: relative;
  width: 95px;
  height: 80px;
  padding: 15px;
  margin: 0 auto;
  background-image: url('../assets/images/gender-btn.png');
  background-repeat: no-repeat;
  background-size: contain;
  transition: all 0.2s ease-in-out;
  &.close {
    padding: 25px;
  }
  .gendericon {
    display: block;
    position: relative;
    margin: 0 auto;
    height: 100%;
    width: auto;
  }
  &.close .gendericon {
    max-width: 30px;
    max-height: 30px;
  }
}
</style>
