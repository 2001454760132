// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/gender-btn.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".btn_effect{transform:scale(.9)}.btn_gender{position:relative;width:95px;height:80px;padding:15px;margin:0 auto;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-size:contain;transition:all .2s ease-in-out}.btn_gender.close{padding:25px}.btn_gender .gendericon{display:block;position:relative;margin:0 auto;height:100%;width:auto}.btn_gender.close .gendericon{max-width:30px;max-height:30px}", ""]);
// Exports
module.exports = exports;
